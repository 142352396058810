<template>
  <ott-dialog
    v-if="clients.activePackagesModal"
    :is-open="clients.activePackagesModal"
    :width="400"
    :show-footer="false"
    @outside="closeModal"
    @resetDataModal="closeModal"
  >
    <template #modalBody>
      <div class="P-table">
        <div class="P-table__header">
          <ul class="G-flex">
            <li>Package name</li>
            <li>Expire date</li>
          </ul>
        </div>
        <div class="P-table__body" v-if="packagesList.length">
          <div v-for="(item, index) in packagesList" :key="index">
            <ul
                v-if="item.expireDate"
                :key="index"
                class="G-flex"
            >
              <li>{{ getPackageName(item.packageName)  }}</li>
              <li>{{ item.expireDate }}</li>
            </ul>
          </div>
        </div>
        <v-skeleton-loader
            v-if="!packagesList.length"
            type="article"
        />
      </div>
    </template>
  </ott-dialog>
</template>

<script>
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "ActivePackagesModal",
  components: { OttDialog },
  data() {
    return {
      activePackages: [
        {
          name: "example 1",
          expireDate: "date",
        },
        {
          name: "example 2",
          expireDate: "date",
        },
      ],
      packagesList:[]
    };
  },
  computed: {
    ...mapState({
      clients: (state) => state.clientsModule,
      clientId: (state) => state.clientsModule.clientId,
      clientEquipments: (state) => state.equipments.clientEquipments,
      locationList: (state) => state.locationClient.locationList,
      packagesClients: (state) => state.packagesClients,
      dataForPackages: (state) => state.packagesClients.dataForPackages,
    }),
  },

  async created() {
    //add client, equipments keys for get packages data
    const packagesData = {
      locations: [],
    };

    this.locationList.map((item) => {
      packagesData.locations.push({
        locationId: item.id,
        roomsCountOld: item.roomsCount,
        roomsCountNew: null,
        actionType: 1,
        dayMonthType: "month",
        dayMonthValue: 0,
        subscribeToDate: this.$moment(new Date()).add(1,"days"),
        totalPrice: null,
        packagesList: [],
        packageInfos: [],
      });
    });

    this.setData({
      packagesData,
    });

    /**get data without sending actions, for show selected actions,
     but first time don't send actions,
     for get buyed actions without expire new, and new total price**/
    // this.dataForGetPackagesWithoutAction()
    // await this.getLocationPackages({ ...this.dataForPackages })

    await this.getPackages();

  },
  methods: {
    ...mapMutations({
      setActivePackagesModal: "clientsModule/setActivePackagesModal",
      setData: "packagesClients/setData",
      setRoomsCount: "packagesClients/setRoomsCount",
      setPackagesDataLocationsKeys:
          "packagesClients/setPackagesDataLocationsKeys",
      setDataLocations: "locationClient/setData",
      resetClientPackages: 'packagesClients/resetState',
    }),
    ...mapActions({
      getLocationPackages: "packagesClients/getLocationPackages",
    }),

    packagesData() {
      return this.packagesClients.packagesData;
    },
    selectedLocation() {
      return this.packagesClients.selectedLocation;
    },

    closeModal() {
      this.setActivePackagesModal(false);
      this.resetClientPackages()
    },
    async getPackages() {
      this.dataForGetPackages();
      await this.getLocationPackages({ ...this.dataForPackages }).then(()=>{
        this.setPackagesList()
      })
    },

    dataForGetPackages() {
      let obj = {};
      const dataForPackages = {
        locations: [],
        equipments: [],
        client: "",
      };

      dataForPackages.locations = [];
      dataForPackages.client = this.clientId;
      dataForPackages.equipments = [...this.clientEquipments]
      dataForPackages.equipments = dataForPackages.equipments.filter(item => item.id === undefined)
      dataForPackages.equipments.map((item) => {
        if(typeof item.equipment === 'object') {
          item.equipment = item.equipment.id
        }
        delete item.accessToDelete
        delete item.type
        delete item.purchaseDate
        delete item.returnDate
      });
      if (this.packagesClients.packagesData.locations) {
        this.packagesClients.packagesData.locations.map((item) => {
          obj.locationId = item.locationId;
          obj.packageInfos = [...item.packageInfos];

          obj.recurringPaymentInfos = obj.recurringPaymentInfos !== undefined  ? obj.recurringPaymentInfos : [];
          //get recurringPaymentInfos for every locations package
          item.packagesList.map((p) => {
            obj.recurringPaymentInfos.push({
              packageId: p.packageId,
              recurringPayment: p.recurringPayment
            })

            if (p.expireDate && !item.packageInfos.includes(p.packageId)) {
              obj.packageRemoves = []

              obj.packageRemoves.push(p.packageId)
            }
          })

          //get roomsCountNew
          item.roomsCountNew
              ? (obj.room = item.roomsCountNew)
              : (obj.room = item.roomsCountOld);

          //get actions

          if (item.actionType === 1) {
            obj.globalAction = item.actionType;
            item.dayMonthType === "month"
                ? (obj.month = item.dayMonthValue)
                : (obj.day = item.dayMonthValue);
          }

          if (item.actionType === 3) {
            obj.globalAction = item.actionType;
            item.subscribeToDate
                ? (obj.subscribeToDate = item.subscribeToDate)
                : "";
          }

          if (item.actionType !== 1 && item.actionType !== 3) {
            obj.globalAction = item.actionType;
          }

          // if (item.actionType === null) {
          //   delete obj.globalAction
          // }

          dataForPackages.locations.push(obj);

          obj = {};
        });
      }

      this.setData({ dataForPackages });
    },
    setPackagesList() {
      let packagesList = [];
      if ( this.packagesClients.packagesData.locations !== undefined) {
        this.packagesClients.packagesData.locations.map((item) => {
          if (item.locationId === this.packagesClients.selectedLocation.id) {
            packagesList = [...item.packagesList]
          }
        });
      }
      console.log(packagesList)
      this.packagesList = packagesList
    },
    getPackageName(nameArray) {
      if (nameArray && nameArray.length) {
        let engName = nameArray.filter(
            (name) => name.lang === "en" || name.lang === "EN"
        );
        let ruName = nameArray.filter(
            (name) => name.lang === "ru" || name.lang === "RU"
        );
        if (engName && engName.length) {
          return engName[0].name;
        } else {
          return ruName[0].name;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";

.P-table {
  padding: 20px;
  ul {
    padding: 0;
    li {
      width: 50%;
      border: 1px solid $neutral-color45;
      border-right: none;
      padding: 8px;
      font-size: $txt14;
      color: $secondary-color;
      &:last-child {
        border-right: 1px solid $neutral-color45;
      }
    }
  }
  &__header {
    ul {
      li {
        font-weight: $semiBold;
      }
    }
  }
}
</style>
<template>
  <div class="P-filter-clients">
    <div class="P-input-block">
      <ott-input
        v-model="filterModel.search"
        @timeoutInput="filterData('search')"
        :timeoutInput="true"
        label="Search"
        prependInnerIcon="mdi mdi-magnify  neutral--text"
      ></ott-input>
    </div>

    <div class="P-input-block">
      <ott-select
        v-model="filterModel.subscriptionState"
        @emitFunc="filterData('subscriptionState')"
        
        :height="38"
        :items="statusesList"
        :clear="true"
        outlined
        selected-text="name"
        label="Status"
      />
    </div>
    <transition name="fade">
      <div v-if="filterModel.subscriptionState" class="P-input-block">
        <ott-select
          v-model="filterModel.statusFilterType"
          @emitFunc="filterData('statusFilterType')"
          :height="38"
          :items="statusTypes"
          :clear="true"
          outlined
          selected-text="name"
          label="Status Type"
        />
      </div>
    </transition>

    <div class="P-input-block">
      <ott-select
        v-model="filterModel.providers"
        @emitFunc="filterData('providers')"
        :height="38"
        :items="resellersList"
        :clear="true"
        :multiple="true"
        outlined
        selected-text="name"
        label="Providers"
      />

    </div>

    <!-- <div class="P-input-block">  TODO need to check with backend then uncomment
      <ott-select-autocomplete
        v-model="filterModel.reseller"
        @emitFunc="filterData('reseller')"
        :items="resellersList"
        :height="38"
        :clear="true"
        outlined
        selected-text="name"
        label="Reseller"
      />
    </div> -->
    <div class="P-input-block">
      <ott-select
        v-model="filterModel.priceGroup"
        :items="priceGroupList"
        :multiple="false"
        :persistentHint="true"
        :isTranslate="true"
        :clear="true"
        label="Price Group"
        selected-text="name"
        @emitFunc="filterData('priceGroup')"
      /> 
    </div>

    <!-- <div class="P-input-block"> TODO need to check with backend
      <ott-select
        v-model="filterModel.currency"
        @emitFunc="filterData('currency')"
        :items="currencyList"
        :multiple="false"
        :clear="true"
        :persistentHint="true"
        :isTranslate="true"
        selected-text="name"
        label="Currency"
      />

    </div> -->
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.balance"
        :min="filterRangeData.balance[0]"
        :max="filterRangeData.balance[1]"
        @updateRange="filterData"
        @clearData="resetData('balance')"
        label="Balance"
        prefix="$"
      ></ott-range-select>
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.credit"
        :min="filterRangeData.credit[0]"
        :max="filterRangeData.credit[1]"
        @updateRange="filterData"
        @clearData="resetData('credit')"
        label="Credit"
        prefix="$"
      ></ott-range-select>
    </div>
    <div class="P-input-block G-date-picker G-flex G-align-center">
      <DatePicker
        v-model="filterModel.creditDate"
        :masks="{ input: ottDateFormat }"
        :max-date="newDateWithMainUserTZ"
        :popover="{ visibility: 'click' }"
        :is-dark="$vuetify.theme.dark"
        @input="filterData"
        locale="en"
        is-range
        class="ott-date-picker"
      >
        <template v-slot="{ inputValue, inputEvents }">
            <div class="ott-date-picker-cont Ott-input-class:">
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.start"
                        v-on="inputEvents.start"
                        outlined
                        dense
                        height="38"
                        label="Credit Start"
                    />
                    <span
                        class="P-date-icon neutral--text"
                        @click="resetData('creditDate')"
                        :class="
                          !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                    ></span>
                </div>
                <span class="pick-to neutral--text">To</span>
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.end"
                        v-on="inputEvents.end"
                        outlined
                        dense
                        height="38px"
                        label="Credit End"
                    />
                    <span
                        @click="resetData('creditDate')"
                        :class="
                            !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                        class="P-date-icon neutral--text"
                    ></span>
                </div>
            </div>
        </template>
      </DatePicker>        
    </div>
    <div class="P-input-block G-flex G-align-center">
      <ott-checkbox
        v-model="filterModel.creditExpired"
        @changeCheckbox="filterData('creditExpired')" 
        label="Credit Expired"
      />
      <ott-checkbox
        v-model="filterModel.creditAutoExtend"
        @changeCheckbox="filterData('creditAutoExtend')"
        class="P-ml-20"
        label="Credit Autoextend"
      />
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.debt"
        :min="filterRangeData.debt[0]"
        :max="filterRangeData.debt[1]"
        @updateRange="filterData"
        @clearData="resetData('debt')"
        label="Debt"
        prefix="$"
      ></ott-range-select>
    </div>
    <div class="P-input-block G-flex G-align-center">
      <ott-checkbox
        v-model="filterModel.isBlocked"
        @changeCheckbox="filterData('isBlocked')"
        label="Is Blocked"
      />
      <ott-checkbox
        v-model="filterModel.inPaused"
        @changeCheckbox="filterData('inPaused')"
        class="P-ml-20"
        label="In Pause"
      />
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.activePackages"
        :min="filterRangeData.activePackages[0]"
        :max="filterRangeData.activePackages[1]"
        @updateRange="filterData"
        @clearData="resetData('activePackages')"
        label="Active Packages"
      ></ott-range-select>
    </div>

    <div class="P-input-block G-date-picker G-flex G-align-center">
      <DatePicker
        v-model="filterModel.packagesDate"
        :masks="{ input: ottDateFormat }"
        :popover="{ visibility: 'click' }"
        :is-dark="$vuetify.theme.dark"
        @input="filterData"
        locale="en"
        is-range
        class="ott-date-picker"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div class="ott-date-picker-cont Ott-input-class:">
              <div class="G-calendar-input">
                  <v-text-field
                      v-model="inputValue.start"
                      v-on="inputEvents.start"
                      outlined
                      dense
                      height="38"
                      label="Package Start"
                  />
                  <span
                      class="P-date-icon neutral--text"
                      @click="resetData('packagesDate')"
                      :class="
                        !inputValue.start
                          ? 'mdi mdi-calendar-blank-outline P-calendar'
                          : 'icon-cancel'
                      "
                  ></span>
              </div>
              <span class="pick-to neutral--text">To</span>
              <div class="G-calendar-input">
                  <v-text-field
                      v-model="inputValue.end"
                      v-on="inputEvents.end"
                      outlined
                      dense
                      height="38px"
                      label="Package End"
                  />
                  <span
                      @click="resetData('packagesDate')"
                      :class="
                          !inputValue.start
                          ? 'mdi mdi-calendar-blank-outline P-calendar'
                          : 'icon-cancel'
                      "
                      class="P-date-icon neutral--text"
                  ></span>
              </div>
          </div>
        </template>
      </DatePicker>
    </div>
    <div class="P-input-block G-flex G-align-center">
      <div class="me-6">
        <ott-checkbox
          label="Package Expired"
          v-model="filterModel.packageExpired"
          @changeCheckbox="filterData('packageExpired')"
        />
      </div>

      <ott-checkbox
        v-model="filterModel.autopayment"
        @changeCheckbox="filterData('autopayment')"
        label="Autopayment"
      />
    </div>

    <div class="P-input-block">
      <ott-select
        label="Server"
        :items="serverList"
        :multiple="false"
        :persistentHint="true"
        selected-text="name"
        :clear="true"
        v-model="filterModel.server"
        @emitFunc="filterData('server')"
      >
      </ott-select>
    </div>
    <div class="P-input-block">
      <ott-select
        label="Timezone"
        :items="timeZoneList"
        :multiple="false"
        :persistentHint="true"
        selected-text="name"
        :clear="true"
        v-model="filterModel.timezone"
        @emitFunc="filterData('timezone')"
      >
      </ott-select>
    </div>
    <SelectedFiltersList v-if="filterModel.filterCount.length" />
  </div>
</template>
<script>
import FilterDataMixin from "./FilterDataMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";
export default {
  mixins: [DateFormatMixin, FilterDataMixin, ConvertTimeZoneMixin],
};
</script>
<style lang="scss" scoped>
.P-mb-filter {
  margin-bottom: 26px;
  position: relative;
}

.P-filter-clients::v-deep {
  .v-text-field__details,
  .v-messages {
    display: none;
  }

  .v-input__slot,
  .range-select {
    margin-bottom: 0;
  }
}

.P-input-block {
  margin-bottom: 20px;
}

.P-ml-20 {
  margin-left: 30px;
}
</style>

import { mapActions, mapMutations, mapState } from "vuex"

export default {
  data() {
    return {
      defaultColumnConfigData: [],
      columnConfigData: [],
      actions: [
        { title: 'Edit', value: 'edit' },
        { title: 'Delete', value: 'delete' },
        // { title: 'Open Support Panel', value: 'openSupportPanel' },
        { title: 'Manage Packages', value: 'managePackages' },
        { title: 'Manage Used Devices', value: 'manageUsedDevices' },
        // { title: 'Manage Profiles', value: 'manageProfiles' },
        // { title: 'Activity Log', value: 'activityLog' },
        { title: 'Go To Transactions Table', value: 'goToTransactionsTable' },
        { title: 'Block Location', value: 'blockLocation' },
        { title: 'Unblock Location', value: 'unblockLocation' },
        { title: 'Pause Location', value: 'pauseLocation' },
        { title: 'Disable Pause', value: 'disablePause' },
        { title: 'Change Server', value: 'changeServer' },
        { title: 'Send Notification', value: 'sendNotification' },
      ],

      multipleActions: [
        { title: 'Pause Locations', value: 'pauseLocations' },
        { title: 'Disable Locations Pause', value: 'disableLocationsPause' },
        { title: 'Block Locatrions', value: 'blockLocations' },
        { title: 'Unblock Locations', value: 'unblockLocations' },
        // { title: 'Activity Log', value : 'activityLog' },
        { title: 'Go To Transactions Table', value: 'goToTransactionsTable' },
        { title: 'Change Server', value: 'changeServer' },
        { title: 'Send Notification', value: 'sendNotifications' },
      ],

      deletedClientId: null,
      warningModalType: "",
      warningModalBtnText: "",
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      loading: false
    }
  },
  computed: {
    ...mapState({
      filterModel: state => state.clientsModule.filterModel,
      locationSyncState: state => state.appModule.locationSyncState,
      // important variables vor get list and  change  columns
      clients: state => state.clientsModule,
      extendedType: state => state.clientsModule.extendedType,
      dataTable: state => state.clientsModule.dataTable,
      filter: state => state.clientsModule.filter,
      defaultSettings: state => state.clientsModule.defaultSettings,
      updateColumnSettingsCommit: state => state.clientsModule.updateColumnSettingsCommit,
      updateColumnsFromBack: state => state.clientsModule.updateColumnsFromBack,
      isDefault: state => state.clientsModule.isDefault,
      filterData: state => state.clientsModule.filterData,
      usedDevicesClient: state => state.usedDevicesClient,
      currencyList: state => state.financeClient.currencyList,
      paymentForPackagesObj: state => state.financeClient.paymentForPackagesObj,
      isOpenCheckoutModal: state => state.checkoutClients.modal.isOpen,
      locationList: state => state.locationClient.locationList,
      packagesClients: state => state.packagesClients,
      resellersList: state => state.providers.resellersList,
    }),

    defaultColumnConfigs() {
      return [
        {
          title: "Full Name",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          key: "firstname",
          defaultHide: true,
          defaultDragIndex: 0,
          mainIndex: 0,
          sortData: {
            isSort: true,
            sortKey: "fullname",
            sortValue: ""
          },
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId ? row.clientId.personalInfo.firstname + ' ' + row.clientId.personalInfo.lastname : ''}</p>`
            : `<p class="G-text-table">${row.personalInfo ? row.personalInfo.firstname + ' ' + row.personalInfo.lastname : ''}</p>`
        },
        {
          title: "ID",
          isTranslate: false,
          style: { minWidth: 100 },
          isShow: true,
          isDrag: true,
          key: "number_id",
          defaultHide: true,
          defaultDragIndex: 1,
          mainIndex: 1,
          sortData: {
            isSort: true,
            sortKey: "number_id",
            sortValue: ""
          },
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId ? row.clientId.number_id : '-'}  <span class="mdi mdi-check" style="color: ${this.getSyncColor(this.locationSyncState[row.id], row.syncState)}"></span></p>`
            : `<p class="G-text-table">${row.number_id !== undefined ? row.number_id : '-'}</p>`
        },
        {
          title: "Status",
          isTranslate: false,
          style: { minWidth: 180 },
          isShow: true,
          isDrag: true,
          key: "status",
          defaultHide: true,
          defaultDragIndex: 2,
          mainIndex: 2,
          sortData: {
            isSort: true,
            sortKey: "clientStatus",
            sortValue: ""
          },
          cellView: row => `<p>${this.getLocationStatus(this.clients.extendedType ? { isBlockLocation: row?.isBlockLocation, isPauseSubscriptions: row?.isPauseSubscriptions, subscriptionState: row?.subscriptionState, subscriptionExpireDate: row?.subscriptionExpireDate, } : { inBlock: row?.inBlock, inPause: row?.inPause, subscriptionState: row?.subscriptionState})}</p>`
        },
        {
          title: "Provider",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          key: "provider",
          defaultHide: true,
          defaultDragIndex: 3,
          mainIndex: 3,
          sortData: {
            isSort: true,
            sortKey: "provider",
            sortValue: ""
          },
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId && row.clientId?.providers ? row.clientId.providers[0]?.name[0]?.name : '-'}</p>`
            : `<p class="G-text-table">${row.provider ? row.provider?.name[0]?.name : '-'}</p>`
        },
        // {
        //   title: "Reseller",
        //   isTranslate: false,
        //   style: { minWidth: 160 },
        //   isShow: true,
        //   isDrag: true,
        //   key: "reseller",
        //   defaultHide: true,
        //   defaultDragIndex: 4,
        //   mainIndex: 4,
        //   sortData: {
        //     isSort: true,
        //     sortKey: "reseller",
        //     sortValue: ""
        //   },
        //   cellView: row => this.clients.extendedType
        //     ? '-'
        //     : `<p class="G-text-table">${row.resellers && row.resellers.length ? row.resellers[0].name[0].name : '-'}</p>`
        // },
        {
          title: "Phone Number",
          isTranslate: false,
          style: { minWidth: 200, maxWidth: 260 },
          isShow: true,
          isDrag: true,
          key: "phone",
          defaultHide: true,
          defaultDragIndex: 5,
          mainIndex: 5,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId ? row.clientId.phones && row.clientId.phones.length ? this.getPhoneNumber(row.clientId.phones) : '-' : '-'}</p>`
            : `<p class="G-text-table">${row.phones && row.phones.length ? this.getPhoneNumber(row.phones) : '-'}</p>`
        },
        {
          title: "Email Address",
          isTranslate: false,
          style: { minWidth: 200, maxWidth: 260 },
          isShow: true,
          isDrag: true,
          key: "email",
          defaultHide: true,
          defaultDragIndex: 6,
          mainIndex: 6,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId ? row.clientId.emails && row.clientId.emails.length ? this.getEmail(row.clientId.emails) : '-' : '-'}</p>`
            : `<p class="G-text-table">${row.emails && row.emails.length ? this.getEmail(row.emails) : '-'}</p>`
        },
        {
          title: "Package Expire",
          isTranslate: false,
          style: { minWidth: 180 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "packageExpire",
            sortValue: ""
          },
          key: "packageExpireDate",
          defaultHide: true,
          defaultDragIndex: 7,
          mainIndex: 7,
          cellView: row => this.clients?.extendedType ? 
            `<p class="G-text-table">${row?.packageExpireDate !== undefined ? row?.packageExpireDate : '-'}</p>`: 
            row?.info?.locations && row?.info?.locations.length ?
            row.info.locations.map(item =>
              `<p class="G-text-table G-sub-column">${item.subscriptionExpireDate ? item.subscriptionExpireDate : '-'}</p>`
            ).join("") : '-'
        },
        {
          title: "Address",
          isTranslate: false,
          style: { minWidth: 300 },
          isShow: true,
          isDrag: true,
          key: "address",
          defaultHide: true,
          defaultDragIndex: 8,
          mainIndex: 8,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId ? row.clientId.addresses && row.clientId.addresses.length ? this.getAddress(row.clientId.addresses) : '-' : '-'}</p>`
            : `<p class="G-text-table">${row.addresses && row.addresses.length ? this.getAddress(row.addresses) : '-'}</p>`
        },
        {
          title: "Price Group",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          key: "priceGroup",
          defaultHide: true,
          defaultDragIndex: 9,
          mainIndex: 9,
          sortData: {
            isSort: true,
            sortKey: "priceGroup",
            sortValue: ""
          },
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId ? this.getPriceGroup(row.clienId) : '-'}</p>`
            : `<p class="G-text-table">${this.getPriceGroup(row)}</p>`
        },
        {
          title: "Currency",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          key: "currency",
          defaultHide: true,
          defaultDragIndex: 10,
          mainIndex: 10,
          sortData: {
            isSort: true,
            sortKey: "currency",
            sortValue: ""
          },
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId ? this.getCurrencyName(row.clientId.finance) : '-'}</p>`
            : `<p class="G-text-table">${this.getCurrencyName(row.finance)}</p>`
        },
        {
          title: "Last Payment Method",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          key: "lastPaymentMethod",
          defaultHide: true,
          defaultDragIndex: 11,
          mainIndex: 11,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId ? row.clientId.lastPaymentMethod ? row.clientId.lastPaymentMethod : '-' : '-'}</p>`
            : `<p class="G-text-table">${row.lastPaymentMethod ? this.getLastPaymentMethod(row.lastPaymentMethod) : '-'}</p>`
        },
        {
          title: "Monthly Payments",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          className: "P-border-dashed",
          key: "monthlyPayments",
          defaultHide: true,
          defaultDragIndex: 12,
          mainIndex: 12,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">
                ${
                  row?.clients && ('monthlyPayments' in row?.clientId)
                    ? this.numberToFinanceNumber(row.clientId.monthlyPayments)
                    : '-'
                }
              </p>`
            : `<p class="G-text-table">
                ${
                  ('monthlyPayments' in row)
                    ? this.numberToFinanceNumber(row.monthlyPayments)
                    : '-'
                }
          
              </p>`
        },
        {
          title: "Subscriptions Recurring Payment",
          isTranslate: false,
          style: { minWidth: 250 },
          isShow: true,
          isDrag: true,
          className: "P-border-dashed",
          key: "subscriptionRecurringPayment",
          defaultHide: true,
          defaultDragIndex: 13,
          mainIndex: 13,
          cellView: row => this.clients.extendedType
            ? '-'
            : `<p class="G-text-table">${ this.clientPaymentForPackagesName(row?.finance) }</p>`
        },
        {
          title: "Recurring Date",
          isTranslate: false,
          style: { minWidth: 250 },
          isShow: true,
          isDrag: true,
          className: "P-border-dashed",
          key: "recurringDate",
          defaultHide: true,
          defaultDragIndex: 14,
          mainIndex: 14,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.locationName !== undefined ? row.locationName : '-'}</p>`
            : row.locations && row.locations.length && row?.finance?.forPackages?.id ?
              row.locations.map(item =>
                `<p class="G-text-table G-sub-column">${item?.subscriptionExpireDate ? this.$moment(item.subscriptionExpireDate).subtract(1, 'days').format(this.ottDateFormat + ' hh:mm A') : '-'}</p>`
              ).join("") : '-'
        },
        {
          title: "Balance",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "balance",
            sortValue: ""
          },
          className: "P-border-dashed",
          key: "balance",
          defaultHide: true,
          defaultDragIndex: 15,
          mainIndex: 15,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">
                ${
                  row?.clientId  && ('balance' in row?.clientId)
                    ? this.numberToFinanceNumber(row.clientId.balance)
                    : '-'
                }
              </p>`
            : `<p class="G-text-table">
                ${
                  this.numberToFinanceNumber(row.balance)
                }
              </p>`
        },
        {
          title: "Credit",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "credit",
            sortValue: ""
          },
          className: "P-border-dashed",
          key: "credit",
          defaultHide: true,
          defaultDragIndex: 16,
          mainIndex: 16,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">
                ${
                  row?.clientId  && ('balance' in row?.clientId)
                    ? this.numberToFinanceNumber(row.clientId.balance)
                    : '-'
                }
              </p>`
            : `<p class="G-text-table">
                ${
                  row.credits && row.credits.length 
                    ? this.getCreditAmount(row.credits)
                    : '$0.00'
                    
                }
              </p>`
        },
        {
          title: "Credit Grant Date",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          key: "creditStartDate",
          defaultHide: true,
          defaultDragIndex: 17,
          mainIndex: 17,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId ? row.clientId.credits && row.clientId.credits.length ? this.getCreditStartDate(row.clientId.credits) : '-' : '-'}</p>`
            : `<p class="G-text-table">${row.credits && row.credits.length ? this.getCreditStartDate(row.credits) : '-'}</p>`
        },
        {
          title: "Credit Expire Date",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "creditExpireDate",
            sortValue: ""
          },
          key: "creditExpireDate",
          defaultHide: true,
          defaultDragIndex: 18,
          mainIndex: 18,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId ? row.clientId.credits && row.clientId.credits.length ? this.getCreditExpireDate(row.clientId.credits) : '-' : '-'}</p>`
            : `<p class="G-text-table">${row.credits && row.credits.length ? this.getCreditExpireDate(row.credits) : '-'}</p>`
        },
        {
          title: "Days Remains To Credit Expire",
          isTranslate: false,
          style: { minWidth: 250 },
          isShow: true,
          isDrag: true,
          key: "creditTerm",
          defaultHide: true,
          defaultDragIndex: 19,
          mainIndex: 19,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId ? row.clientId.credits && row.clientId.credits.length ? this.getRemainDaysForCreditExpire(row.clientId.credits) : '-' : '-'}</p>`
            : `<p class="G-text-table">${row.credits && row.credits.length ? this.getRemainDaysForCreditExpire(row.credits) : '-'}</p>`
        },
        {
          title: "Credit Autoextend",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "creditAutoExtend",
            sortValue: ""
          },
          key: "creditAutoextend",
          defaultHide: true,
          defaultDragIndex: 20,
          mainIndex: 20,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.clientId ? row.clientId.credits && row.clientId.credits.length ? this.getCreditAutoExtend(row.clientId.credits) : '-' : '-'}</p>`
            : `<p class="G-text-table">${row.credits && row.credits.length ? this.getCreditAutoExtend(row.credits) : '-'}</p>`
        },
        {
          title: "Debt",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "debt",
            sortValue: ""
          },
          className: "P-border-dashed",
          key: "debt",
          defaultHide: true,
          defaultDragIndex: 21,
          mainIndex: 21,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">
                ${
                  row.clientId && ('debt' in row.clientId)
                    ? this.numberToFinanceNumber(row.clientId.debt)
                    : '-'
                }
            </p>`
            : `<p class="G-text-table">
                ${
                  ('debt' in row)
                    ? this.numberToFinanceNumber(row.debt)
                    : '-'
                }
            </p>`
        },
        {
          title: "Location",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          key: "locationName",
          defaultHide: true,
          defaultDragIndex: 22,
          mainIndex: 22,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.locationName !== undefined ? row.locationName : '-'}</p>`
            : row.locations && row.locations.length ?
              row.locations.map(item =>
                `<p class="G-text-table G-sub-column">${item.locationName}</p>`
              ).join("") : '-'
        },
        {
          title: "Login",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "login",
            sortValue: ""
          },
          key: "login",
          defaultHide: true,
          defaultDragIndex: 23,
          mainIndex: 23,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.login !== undefined ? row.login : '-'}</p>`
            : row.locations && row.locations.length ?
              row.locations.map(item =>
                `<p class="G-text-table G-sub-column">${item.login}</p>`
              ).join("") : '-'
        },
        {
          title: "Room",
          isTranslate: false,
          style: { minWidth: 100 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "room",
            sortValue: ""
          },
          key: "roomsCount",
          defaultHide: true,
          defaultDragIndex: 24,
          mainIndex: 24,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.roomsCount !== undefined ? row.roomsCount : '-'}</p>`
            : row.locations && row.locations.length ?
              row.locations.map(item =>
                `<p class="G-text-table G-sub-column">${item.roomsCount}</p>`
              ).join("") : '-'
        },
        // {
        //   title: "Profile",
        //   isTranslate: false,
        //   style: {minWidth: 160},
        //   isShow: true,
        //   isDrag: true,
        //   key: "profile",
        //   "defaultHide": true,
        //   "defaultDragIndex": 25,
        //   "mainIndex": 25,
        //   cellView: row => '-'
        // },
        {
          title: "Server",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "server",
            sortValue: ""
          },
          key: "server",
          defaultHide: true,
          defaultDragIndex: 26,
          mainIndex: 26,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.server !== undefined ? row.server.name[0].name : '-'}</p>`
            : row.locations && row.locations.length ?
              row.locations.map(item =>
                `<p class="G-text-table G-sub-column">${item.server?.name?.length ? item.server.name[0].name : item.server }</p>`
              ).join("") : '-'
        },
        {
          title: "Timezone",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          key: "timezone",
          defaultHide: true,
          defaultDragIndex: 27,
          mainIndex: 27,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">UTC ${row.timezone && row.timezone > 0 ? '+' + row.timezone : '-'}</p>`
            : row.locations && row.locations.length ?
              row.locations.map(item =>
                `<p class="G-text-table G-sub-column">UTC ${item.timezone && item.timezone > 0 ? '+' + item.timezone : item.timezone}</p>`
              ).join("") : '-'
        },
        {
          title: "Active Packages",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          isColumnClicked: true,
          key: "activePackages",
          defaultHide: true,
          defaultDragIndex: 28,
          mainIndex: 28,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.activePackages !== undefined ? row.activePackages : '-'}</p>`
            : row.locations && row.locations.length ?
              row.locations.map(item =>
                `<p class="G-text-table P-active-packages G-sub-column">${item.activePackages}</p>`
              ).join("") : '-'
        },
        {
          title: "Pause Start",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          isColumnClicked: true,
          key: "pauseStart",
          defaultHide: true,
          defaultDragIndex: 29,
          mainIndex: 29,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.pauseStartDate !== undefined ? row.pauseStartDate : '-'}</p>`
            : row.locations && row.locations.length ?
              row.locations.map(item =>
                `<p class="G-text-table P-active-packages G-sub-column">${item.pauseStartDate !== undefined ? item.pauseStartDate : '-'}</p>`
              ).join("") : '-'
        },
        {
          title: "In Pause",
          isTranslate: false,
          style: { minWidth: 160 },
          isShow: true,
          isDrag: true,
          isColumnClicked: true,
          key: "inPause",
          defaultHide: true,
          defaultDragIndex: 30,
          mainIndex: 30,
          cellView: row => this.clients.extendedType
            ? `<p class="G-text-table">${row.inPause !== undefined ? row.inPause : '-'}</p>`
            : row.locations && row.locations.length ?
              row.locations.map(item =>
                `<p class="G-text-table P-active-packages G-sub-column">${item.inPause !== undefined ? item.inPause : '-'}</p>`
              ).join("") : '-'
        },
      ]
    },

    warningModalHeading() {
      return `${this.warningModalBtnText} Location(s)?`
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> one or more Locations! <br />  If you're not sure, you can cancel this operation.`
    },

    rowsPerPage: {
      set(limit) {
        this.setLimit(limit)
      },
      get() {
        return this.filter.limit;
      }
    },
  },

  watch: {
    'filterModel.autopayment'() {
      this.hideShowColumns()
    },
  },

  async created() {
    this.loading = true
    const extendedType = this.$route.query.extendedType || false;
    this.setExtendedType((extendedType === 'true'));
    await this.getClientSettings()
    this.createColumnConfigData()
    this.sortColumn();
    await Promise.all([
      this.getClientList(this.filterData).finally(() => this.loading = false),
      this.getResellersList(),
      this.getCurrencyList(),
      this.getServerList(),
      this.getBeckupsList()
    ])
    
    await this.getPriceGroupList()
    this.resetSelectedList()
    this.setCurrencyList(this.currencyList)

    this.generalSearchSetData({ isGeneralSearch: false })
  },

  beforeDestroy() {
    this.filterModel.reset()
    this.setData({ filterData: {} })
  },

  destroyed() {
    this.setFilterPage(1)
  },

  methods: {
    ...mapActions({
      getClientList: 'clientsModule/getClientList',
      getClientSettings: 'clientsModule/getClientSettings',
      getServerList: 'clientsModule/getServerList',
      getBeckupsList: 'clientsModule/getBeckupsList',
      getClientData: 'clientsModule/getClientData',
      getResellersList: 'providers/getResellersList',
      setPersonalInfo: 'personalInfoClient/setPersonalInfo',
      setContactData: 'contactClient/setContactData',
      setAddresses: 'addressClient/setAddresses',
      getPaymentMethod: 'paymentMethodClients/getPaymentMethod',
      getBalanceCredit: 'balanceCreditClient/getBalanceCredit',
      getLocations: 'locationClient/getLocations',
      clientDelete: 'clientsModule/clientDelete',
      getCurrencyList: 'financeClient/getCurrencyList',
      doMultipleAction: 'clientsModule/doMultipleAction',
      deleteClients: 'clientsModule/deleteClients',
      getPriceGroupByTypeList: "priceGroupAdmin/getPriceGroupByTypeList",
    }),
    ...mapMutations({
      setFilterPage: 'clientsModule/setFilterPage',
      setLimit: 'clientsModule/setLimit',
      filterClients: 'clientsModule/filterClients',
      sortColumn: 'clientsModule/sortColumn',
      openManagePackagesModal: 'clientsModule/openManagePackagesModal',
      openUsedDevicesModal: 'usedDevicesClient/openUsedDevicesModal',
      setClientId: 'clientsModule/setClientId',
      setData: 'clientsModule/setData',
      setContactMutation: 'contactClient/setData',
      openClientsModal: 'clientsModule/openModal',
      setDisableTabs: "clientsModule/setDisableTabs",
      personalInfoReset: 'personalInfoClient/resetData',
      setFinanceFormData: 'financeClient/setFormData',
      financeSetData: 'financeClient/setData',
      showBalance: 'balanceCreditClient/saveBalance',
      resetBalanceManage: 'balanceCreditClient/resetBalanceManage',
      resetCreditData: 'balanceCreditClient/resetCreditData',
      resetCredit: 'balanceCreditClient/resetCredit',
      setCreditIsStart: 'balanceCreditClient/setIsStart',
      balanceCreditSetData: 'balanceCreditClient/setData',
      setDisableButton: 'clientsModule/setDisableButton',
      setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
      setIsAddClient: 'clientsModule/setIsAddClient',
      setActivePackagesModal: 'clientsModule/setActivePackagesModal',
      toggleOpenPackageList: 'clientsModule/toggleOpenPackageList',
      packagesClientSetData: 'packagesClients/setData',
      resetFilterModel: 'clientsModule/resetFilterModel',
      makeLocationSyncState: 'appModule/makeLocationSyncState',
      setExtendedType: 'clientsModule/setExtendedType',
      generalSearchSetData: 'generalSearch/setData',
      generateAllPriceGroups: "priceGroupAdmin/generateAllPriceGroups",
    }),
    
    hideShowColumns() {
      if (this.filterModel.autopayment) {
        this.columnConfigData.map((item) => {
          if (item.key === 'recurringDate') {
            item.isShow = true
            item.customUnshow = false
            item.disabled = false
          }
        })
      } else {
        this.columnConfigData.map((item) => {
          if (item.key === 'recurringDate') {
            item.isShow = false
            item.customUnshow = true
            item.disabled = true
          }
        })
      }
    },

    getPriceGroupList() {
      this.getPriceGroupByTypeList({ 
        type: 1
      }).then(data => {
        this.generateAllPriceGroups(data)
      })
    },

    async updateLimit() {
      await this.$store.dispatch(this.updateColumnsFromBack, this.defaultSettings).then(() => {
        this.createSaveDefaultData();
      })

      this.sortColumn();
      await this.getClientList(this.filterData)
      this.resetSelectedList()
    },

    async exportData() {
      this.filterClients();
      await this.getClientList({ ...this.filterData, excel: true })
      this.resetSelectedList()
    },

    openChangeServerModal() {
      this.setData({ isOpenChangeServerModal: true })
    },

    async resetDataTableSettings() {
      await this.resetDataTable()
      await this.getClientSettings()
      this.createColumnConfigData()
      this.hideShowColumns()
    },

    async selectReseller(value) {
      this.setFilterPage(1)
      await this.getClientList(this.filterData)
      this.resetSelectedList()
    },

    async selectAction() {
      const [event, data, actionName] = arguments;
      const client = this.getDataFromActions(data);
      const clientId = this.clients.extendedType ? client.clientId.id : client.id;
      this.setClientId(clientId)

      this.multipleBlockPauseData = {}

      if (!this.clients.extendedType) {
        this.multipleBlockPauseData = {
          clients: [clientId]
        }
      }

      if (this.clients.extendedType) {
        this.multipleBlockPauseData = {
          locations: [data.rowData.id]
        }
      }

      switch (actionName) {
        case 'edit': {
          this.setDisableButton(false)
          this.personalInfoReset();
          this.setData({ 
            isClientEdit: true,
            clientProviderId: null
          })
          this.setContactMutation({
            emailSection: true,
            phoneSection: true,
            isEditMode: false,
          });

          //Reset Balance/Credit
          this.resetBalanceManage()
          this.resetCreditData()
          this.balanceCreditSetData({ mainBalance: 0, dept: 0 })

          await this.getClientData(clientId).then(r => {
            this.setData({
              clientProviderId: r.provider?.id
            })
            this.setPersonalInfo({ ...r.personalInfo, provider: r.provider.id }) // for personal info tab
            this.setContactData({ emails: r.emails, phones: r.phones }) // for contact tab
            r.addresses.length ? this.setAddresses({ addresses: r.addresses }) : '' // for addresses tab

            /*For Finance tab set formData*/
            let finance = {
              currency: null,
              priceGroup: null,
              paymentForPackages: null,
              paymentForDevice: null,
              isPaperlessBilling: false
            } // TODO hardcoded.

            let paymentForPackagesObj = null
            r.finance ? finance = { ...r.finance } : this.financeSetData({ showFinanceForm: true })
            paymentForPackagesObj = r.finance?.forPackages
            
            this.setFinanceFormData(finance)
            this.financeSetData({ paymentForPackagesObj })
            /********************************/
          })

          /** for Payment method, Balance/credit, Location tabs **/
          await Promise.all([
            this.getPaymentMethod({ clientId }),
            this.getBalanceCredit(),
            this.getLocations({ clientId })
          ])

          this.openClientsModal();
          this.setShowSaveBtn(false)
          this.setIsAddClient(false)
          break
        }
        case 'delete': {
          this.warningModalBtnText = "Delete"
          this.warningModalType = "delete"
          this.warningModalIsDangerButton = true
          this.setData({ showWarningModal: true })
          this.deletedClientId = clientId
          break
        }
        // case 'openSupportPanel': {
        //   console.log(client, 'Select  client id from x  chane server')
        //   break
        // }
        case 'managePackages': {
          let selectedLocation = null
          await this.getLocations({ clientId });

          if (this.clients.extendedType) {
            selectedLocation = data.rowData;
            selectedLocation._id = selectedLocation.id
          }

          //Before open Manage Packages modal, get selected location and set it as selected in packages store
          if (selectedLocation && this.clients.extendedType) {
            this.locationList.map((location) => {
              if (location._id === selectedLocation._id) {
                selectedLocation = { ...location }
              }
            })

            this.packagesClientSetData({
              selectedLocation,
              openPackagesFromManage: true
            });
          }

          this.openManagePackagesModal();
          break
        }
        case 'manageUsedDevices': {
          await this.getLocations({ clientId });
          this.openUsedDevicesModal()
          break
        }
        // case 'manageProfiles': {
        //   console.log(client, 'Select  client id from x  chane server')
        //   break
        // }
        // case 'activityLog': {
        //   console.log(client, 'Select  client id from x  chane server')
        //   break
        // }
        case 'goToTransactionsTable': {
          this.$router.push({ name: 'TransactionsList' })
          break
        }
        case 'blockLocation': {
          this.multipleBlockPauseData.isBlocked = true;
          this.warningModalBtnText = "Block"
          this.warningModalType = "blockLocation"
          this.warningModalIsDangerButton = true
          this.setData({ showWarningModal: true })
          break
        }

        case 'unblockLocation': {
          this.multipleBlockPauseData.isBlocked = false;
          this.warningModalBtnText = "Unblock"
          this.warningModalType = "unblockLocation"
          this.warningModalIsDangerButton = false
          this.setData({ showWarningModal: true })
          break
        }

        case 'pauseLocation': {
          this.multipleBlockPauseData.inPaused = true;
          this.warningModalBtnText = "Pause"
          this.warningModalType = "pauseLocation"
          this.warningModalIsDangerButton = true
          this.setData({ showWarningModal: true })
          break
        }

        case 'disablePause': {
          this.multipleBlockPauseData.inPaused = false;
          this.warningModalBtnText = "Disable Pause"
          this.warningModalType = "disablePause"
          this.warningModalIsDangerButton = false
          this.setData({ showWarningModal: true })
          break
        }

        case 'changeServer': {
          this.setData({ isOpenChangeServerModal: true })
          console.log(client, 'Select  client id from x  chane server')
          break
        }
        case 'sendNotification': {
          console.log(client, 'Select  client id from x  for send notification')
          break
        }
      }

    },

    selectMultipleAction(actionName) {
      if (this.selectedArrowList.length || this.isSelectedAll) {
        this.multipleBlockPauseData = {}

        
        if (!this.clients.extendedType) {
          this.multipleBlockPauseData = {
            clients: [...this.selectedArrowList.map(item => item.id)]
          }
        }
        
        if (this.clients.extendedType) {
          this.multipleBlockPauseData = {
            locations: [...this.selectedArrowList.map(item => item.id)]
          }
        }
        
        if (this.isSelectedAll) {
          this.multipleBlockPauseData.all = true
        }

        this.setData({ showWarningModal: true })

        switch (actionName) {
          case 'delete': {
            this.warningModalType = 'multipleDelete'
            this.warningModalBtnText = 'Delete'
            this.warningModalIsDangerButton = true
            break
          }
          case 'pauseLocations': {
            this.multipleBlockPauseData.inPaused = true;
            this.warningModalType = 'multiplePause'
            this.warningModalBtnText = 'Pause'
            this.warningModalIsDangerButton = true
            break
          }
          case 'disableLocationsPause': {
            this.multipleBlockPauseData.inPaused = false;
            this.warningModalType = 'multipleDisablePause'
            this.warningModalBtnText = 'Disable Pause'
            this.warningModalIsDangerButton = false
            break
          }
          case 'blockLocations': {
            this.multipleBlockPauseData.isBlocked = true;
            this.warningModalType = 'multipleBlock'
            this.warningModalBtnText = 'Block'
            this.warningModalIsDangerButton = true
            break
          }
          case 'unblockLocations': {
            this.multipleBlockPauseData.isBlocked = false;
            this.warningModalType = 'multipleUnblock'
            this.warningModalBtnText = 'Unblock'
            this.warningModalIsDangerButton = false
            break
          }
          // case 'activityLog': {
          //   break
          // }
          case 'goToTransactionsTable': {
            break
          }
          case 'changeServer': {
            this.setData({ isOpenChangeServerModal: true, showWarningModal: false })
            break
          }
          case 'sendNotifications': {
            break
          }
        }

      }
    },

    async warningModalBtnFunc() {
      await this.warningModalBtnFunction().finally(() => {
        this.closeWarningModal()
      })
    },

    async warningModalBtnFunction() {
      this.warningModalIsLoading = true
      switch (this.warningModalType) {
        case "delete":
          await this.rejectClient()
          break;

        case "blockLocation":
        case "unblockLocation":
        case "pauseLocation":
        case "disablePause":
          await this.doMultipleAction(this.multipleBlockPauseData)
          break;

        case "multipleDelete":
          await this.rejectClient();
          break;

        case "multiplePause":
        case "multipleDisablePause":
        case "multipleBlock":
        case "multipleUnblock":
          await this.doMultipleAction(this.multipleBlockPauseData).then(data => {
            if (data) {
              this.resetSelectedList()
            }
          })
          break;
      }
    },

    closeWarningModal() {
      this.warningModalIsLoading = false;
      this.setData({ showWarningModal: false })
    },

    /**
     * Delete clients
     ***/

    async rejectClient() {
      if (this.deletedClientId) {
        try {
          await this.clientDelete({ clientId: this.deletedClientId })
        } catch (ex) {
          const toastOptions = {
            msg: `${ex.message}`,
            position: "toast-bottom-right",
            defaultTimeout: 500,
            style: {
              backgroundColor: "#FF0000",
              width: "max-content",
              textTransform: "capitalize",
              opacity: '1'
            }
          }
          this.$toastr.Add(toastOptions);
        }
      } else if (this.selectedArrowList.length) {
        const data = {
          clientId: [...this.selectedArrowList.map(item => item.id)]
        }

        if (this.isSelectedAll) {
          data.all = true
        }

        await this.deleteClients(data)
        this.resetSelectedList()
      }
    },

    /**
     * Reset filter data
     * **/

    async resetFilterData() {
      this.filterModel.reset()
      this.resetFilterModel()
      await this.getClientList(this.filterData)
      this.resetSelectedList()
    },

    /**
     * open modal  create and edit  client
     * **/

    openModal() {
      if (this.clients.isClientEdit) {
        this.setData({
          isClientEdit: false,
          clientId: null
        })

        this.setDisableTabs(0);
        this.personalInfoReset();
      }
      
      this.setData({
        isAddClient: true
      })
      
      setTimeout(() => { 
        this.openClientsModal() 
      }, 100)
    },

    async updateClientsTable(page) {
      if (page) {
        this.setFilterPage(page)
      }
      this.sortColumn();
      await this.getClientList(this.filterData)
      this.resetSelectedList()
    },

    async updatedExtendedType(extended) {
      
      this.setFilterPage(1)
      this.resetSelectedList()
      this.setExtendedType(extended);
      if ((this.$route.query.extendedType === 'true') !== extended) {
        await this.$router.replace({ path: '/clients/list', query: { extendedType: extended } })
      }
      await this.getClientList(this.filterData)
      this.resetSelectedList()
    },

    async sortColumnData(data) {
      this.sortColumn(data)
      await this.getClientList(this.filterData)
    },

    /**
     * Functions for client list data
     */
    getLocationStatus(data) {
      /*
        location status by subscriptionState value
        0 - Inactive
        1 - Pending
        2 - Canceled
        3 - Active
      */

      let pauseDuration = null
      let expireDate = null
      let pauseStartDate = null
      
      if (data.subscriptionState === 0) {
        return `<span class="G-badge G-advanced-badge">Inactive</span>`
      }

      if (data.subscriptionState === 1) {
        return `
          <span class="G-badge G-pending-badge">
            Pending 
            ${
              data?.subscriptionPendingDate
                ? data.subscriptionPendingDate
                : ""
            }
          </span>`
      }

      if (data.subscriptionState === 2) {
        return `
          <span class="G-badge G-disabled-badge">
            Canceled
            ${
              data?.subscriptionCancelDate
                ? data.subscriptionCancelDate
                : ""
            }
          </span>`
      }
      
      const isLocationStatusType = this.filterModel.statusFilterType === 'location';
      if (this.clients.extendedType) {
        //get pause duration
        if (data?.subscriptionExpireDate) {
          expireDate = this.$moment(data.subscriptionExpireDate)
          pauseStartDate = this.$moment(data.pauseStartDate)
          pauseDuration = this.convertMsToDays(expireDate.diff(pauseStartDate))
        }

        if (data.subscriptionState === 3) {
          if (data.isBlockLocation && data.isPauseSubscriptions) {
            return `<span class="G-badge G-reject-badge mb-2">Blocked</span>
                    <span class="G-badge G-paused-badge G-paused-badge-time">Paused ${pauseDuration}</span>`
          }

          if (data.isBlockLocation && !data.isPauseSubscriptions) {
            return `<span class="G-badge G-reject-badge">Blocked</span>`
          }

          if (!data.isBlockLocation && data.isPauseSubscriptions) {
            return `<span class="G-badge G-paused-badge G-paused-badge-time">Paused ${pauseDuration}</span>`
          }

          if (!data.isBlockLocation && !data.isPauseSubscriptions) {
            return `
              <span class="G-badge G-approve-badge">
                Active
                ${
                  data?.subscriptionActivationDate
                    ? data.subscriptionActivationDate
                    : ""
                }
              </span>`
          }
        }

        if (data.isBlockLocation === undefined || data.isPauseSubscriptions === undefined || (!data.isBlockLocation && !data.isPauseSubscriptions)) {
          return ''
        }
      }

      if (!this.clients.extendedType && !isLocationStatusType) {
        if (data.subscriptionState === 3) {
          if (data.inBlock === 0 && data.inPause === 0) {
            return `<span class="G-badge G-approve-badge">Active</span>`
          }

          if (data.inBlock === 0 && data.inPause === 1) {
            return `<span class="G-badge G-paused-badge">Paused</span>`
          }

          if (data.inBlock === 0 && data.inPause === 2) {
            return `<span class="G-badge G-paused-badge">Semipaused</span>`
          }

          if (data.inBlock === 1 && data.inPause === 0) {
            return `<span class="G-badge G-reject-badge">Blocked</span>`
          }

          if (data.inBlock === 2 && data.inPause === 0) {
            return `<span class="G-badge G-reject-badge">Semiblocked</span>`
          }

          if (data.inBlock === 1 && data.inPause === 1) {
            return `<span class="G-badge G-reject-badge">Blocked</span> <br>
                    <span class="G-badge G-paused-badge">Paused</span>`
          }

          if (data.inBlock === 1 && data.inPause === 2) {
            return `<span class="G-badge G-reject-badge">Blocked</span> <br>
                        <span class="G-badge G-paused-badge">Semipaused</span>`
          }

          if (data.inBlock === 2 && data.inPause === 1) {
            return `<span class="G-badge G-reject-badge">Semiblocked</span> <br>
                    <span class="G-badge G-paused-badge">Paused</span>`
          }

          if (data.inBlock === 2 && data.inPause === 2) {
            return `<span class="G-badge G-reject-badge">Semiblocked</span> <br>
                    <span class="G-badge G-paused-badge">Semipaused</span>`
          }
        }

        if (data.inBlock === undefined || data.inPause === undefined || (data.inBlock === 0 && data.inPause === 0)) {
          return ''
        }
      } else if (!this.clients.extendedType && isLocationStatusType) {

        if (data.subscriptionState === 3) {
          return `<span class="G-badge G-approve-badge">Active</span>`
        }
        if (data.subscriptionState === 4) {
          return `<span class="G-badge G-paused-badge">Paused</span>`
        }
        if (data.subscriptionState === 5) {
            return `<span class="G-badge G-reject-badge">Blocked</span>`
        }
      }
    },

    convertMsToDays(t) {
      var cd = 24 * 60 * 60 * 1000,
        ch = 60 * 60 * 1000,
        d = Math.floor(t / cd),
        h = Math.floor((t - d * cd) / ch),
        m = Math.round((t - d * cd - h * ch) / 60000);

      return `${d}d ${h}h ${m}m`
    },

    getEmail(emails) {
      if (emails.length) {
        let isMain = emails.filter(email => email.isMain)[0]
        if (isMain) {
          return isMain.email
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    },
    getPhoneNumber(phones) {
      if (phones.length) {
        let forCall = phones.filter(phone => phone.forCall)[0]
        if (forCall) {
          return forCall.phone
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    },
    getAddress(addresses) {
      let address = ''
      if (addresses.length) {
        let forContactInvoice = addresses.filter(address => address.forContactInvoice)[0]

        if (forContactInvoice) {
          address += this.FormatClientAddress(forContactInvoice);
          return address
        }

        if (!forContactInvoice) {
          address += this.FormatClientAddress(addresses[0]);
          return address
        }
      } else {
        return '-'
      }
    },
    getCreditAmount(credits) {
      if (credits.length) {
        let credit = credits[0].creditAmount
        return this.numberToFinanceNumber(credit)
      }
    },
    getCreditStartDate(credit) {
      if (credit.length) {
        let lastCredit = credit[credit.length - 1];
        let creditStart = lastCredit.creditStartDate;
        const momentObject = this.$moment(creditStart, this.ottDateFormat);

        return creditStart

        // if (isNaN(new Date(creditStart))) {
        //   return this.$moment(momentObject.toDate()).format(`${this.ottDateFormat} HH:mm`)
        // } else {
        //   return this.$moment(creditStart).format(`${this.ottDateFormat} HH:mm`)
        // }

      } else {
        return '-'
      }
    },
    getCreditExpireDate(credit) {
      if (credit.length) {
        let lastCredit = credit[credit.length - 1];
        let creditStart = lastCredit.creditStartDate
        let creditTerm = lastCredit.creditTerm

        const momentObject = this.$moment(creditStart, this.ottDateFormat);

        if (isNaN(new Date(creditStart))) {
          if (lastCredit.days) {
            return this.$moment(momentObject.toDate())
              .add(creditTerm, 'day')
              .format(`${this.ottDateFormat} HH:mm`);
          } else {
            return this.$moment(momentObject.toDate())
              .add(creditTerm, 'month')
              .format(`${this.ottDateFormat} HH:mm`);
          }
        }

        if (!isNaN(new Date(creditStart))) {
          if (lastCredit.days) {
            return this.$moment(creditStart)
              .add(creditTerm, 'day')
              .format(`${this.ottDateFormat} HH:mm`);
          } else {
            return this.$moment(creditStart)
              .add(creditTerm, 'month')
              .format(`${this.ottDateFormat} HH:mm`);
          }
        }

      } else {
        return '-'
      }
    },
    getRemainDaysForCreditExpire(credit) {
      if (credit.length) {
        let lastCredit = credit[credit.length - 1];
        let creditTerm = lastCredit.creditTerm

        if (lastCredit.days) {
          if (creditTerm <= 5) {
            return `<span style="color: #F53A3A">${creditTerm} days</span>`
          } else {
            return creditTerm + ' days'
          }
        } else {
          return creditTerm + ' month'
        }

      } else {
        return '-'
      }
    },
    getCreditAutoExtend(credit) {
      if (credit.length) {
        if (credit.length === 1) {
          if (credit[0].creditAutoextend) {
            return '<span class="mdi mdi-check" style="font-size: 20px; color: #5FC788; margin-left: 30px" />'
          } else {
            return '-'
          }
        }
        if (credit.length > 1) {
          let lastCredit = credit[credit.length - 1];
          if (lastCredit.creditAutoextend) {
            return '<span class="mdi mdi-check" style="font-size: 20px; color: #5FC788; margin-left: 30px" />'
          } else {
            return '-'
          }
        }
      } else {
        return ''
      }
    },
    getPriceGroup(row) {
      return '-'; // TODO important
      if ('priceGroupObject' in row) {
        if (row.priceGroupObject.length !== 0) {
          return row.priceGroupObject.name[0].name
        } else {
          return '-'
        }
      } else if (row?.finance?.priceGroup === null) {
        return 'Default'
      } else {
        return '-'
      }
    },
    getCurrencyName(finance) {
      if (finance) {
        let result = this.currencyList.filter(currency => currency.id === finance.currency)
        if (result.length && result) {
          let eng = result[0].name.filter(item => item.lang === 'en' || item.lang === 'EN')
          return eng && eng.length ? eng : result[0].name[0].name
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    },
    getLastPaymentMethod(lastPayment) {
      if (lastPayment !== null) {
        let cardNumber = lastPayment.cardNumber

        return `<div class="G-text-table G-flex G-align-center">
                    <div class="P-card-brand" style="background-image: url('${this.getCardBrand(lastPayment.brand)}')"></div>
                    <span>${cardNumber}</span>
                </div>`
      } else {
        return '-'
      }
    },

    actionsList(isBlocked, inPaused, subscriptionState = false) {
      let actions = JSON.parse(JSON.stringify(this.actions));

      if (!this.permissionIs('deleteClient')) {
        actions = actions.filter(item => item.value !== 'delete')
      }

      if (this.permissionIs('updateClientLocation')) {
        if (subscriptionState === 3) {
          if (!isBlocked && !inPaused) {
            actions = actions.filter(item => item.value !== 'unblockLocation' && item.value !== 'disablePause')

          } else if (isBlocked && inPaused) {
            actions = actions.filter(item => item.value !== 'blockLocation' && item.value !== 'pauseLocation')

          } else if (isBlocked && !inPaused) {
            actions = actions.filter(item => item.value !== 'blockLocation' && item.value !== 'disablePause')

          } else if (!isBlocked && inPaused) {
            actions = actions.filter(item => item.value !== 'unblockLocation' && item.value !== 'pauseLocation')
          }
        }

        if (subscriptionState !== 3) {
          actions = actions.filter(item => item.value !== 'blockLocation' && item.value !== 'unblockLocation' && item.value !== 'pauseLocation' && item.value !== 'disablePause')
        }
      }

      return actions;
    },

    setCurrencyList(list) {
      const currencyList = []
      list.map(item => {
        currencyList.push({
          id: item.id,
          value: item.value,
          name: item.code
        })
      })

      this.setData({ currencyList })
    },

    async openActivePackagesModal(row, columnTitle) {
      let clientId = row.id
      this.setData({
        clientId: row.id
      })
      await this.getLocations({ clientId });
      this.setActivePackagesModal(true)
    },

    clientPaymentForPackagesName(data) {
      let name = '-'

      if (data?.forPackages?.id) {
        // name = item.name
        if (data?.forPackages?.bankTransfer) { // getting bank name with bank icon
          name = `
            <div class="G-align-center">
              <svg class="P-bank-account-icon me-1">
                <use xlink:href="/sprite.svg#bank-icon"></use>
              </svg>

              <span>${ data?.forPackages?.bankTransfer?.accountNumber.slice(-4) }</span>
            </div>
          `
        } 
        
        if (data?.forPackages?.creditCard) {  // getting card name with card icon
          name = `
              <div class="G-align-center">
                <div class="P-credit-card-image me-1" style="background-image: url(${ this.getCardBrand(data?.forPackages?.creditCard?.brand) })"></div>
                <span>${
                  data?.forPackages?.creditCard?.cardNumber.slice(-4)
                }</span>
              </div>
            `
        }
      }
      
      return name
    },
  }
}



<template>
    <ott-dialog
        :width="400"
        :is-open="isOpenChangeServerModal"
        :show-footer="false"
        @outside="closeModal"
        @resetDataModal="closeModal"
        modal-title="Change Server"
    >
        <template #modalBody>
            <div class="P-inputs">
                <div class="P-inputs-block">
                    <ott-select
                        v-model="serverMode"
                        :error="$v.changeServerData.mode.$error"
                        :items="serverModeList"
                        label="Mode"
                        selected-text="name"
                    />
                </div>
                <div v-if="changeServerData.mode !== 'restore'" class="P-inputs-block">
                    <ott-select
                        v-model="server"
                        :error="$v.changeServerData.server.$error"
                        :items="serverList"
                        :multiple="false"
                        :persistentHint="true"
                        label="Servers"
                        selected-text="name"
                    />
                </div>
                <div v-if="changeServerData.mode !== 'restore'" class="P-inputs-block">
                    <ott-select
                        v-model="setSerever"
                        :error="$v.changeServerData.setSerever.$error"
                        :items="serverList"
                        :multiple="false"
                        :persistentHint="true"
                        label="Set Server"
                        selected-text="name"
                    />
                </div> 
                <div v-if="changeServerData.mode === 'restore'" class="P-inputs-block">
                    <ott-select
                        v-model="backup"
                        :error="$v.changeServerData.backup.$error"
                        :items="serverBackups"
                        :multiple="false"
                        :persistentHint="true"  
                        label="Backups"
                        selected-text="name"
                    />
                </div>
            
                <div>
                    <ott-button-loader
                        :button-text="btnText"
                        :is-loading="isLoading"
                        @onClick="changeBtnFunc"
                        className="text-capitalize primary-sm-btn"
                    />
                </div>
            </div>
        </template>
    </ott-dialog>
</template>

<script>
    import { mapActions, mapMutations, mapState } from "vuex";
    // components
    import OttDialog from "@/components/vuetifyComponents/OttDialog";
    import OttSelect from "@/components/vuetifyComponents/OttSelect";
    import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
    import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
    import { required, requiredIf } from "vuelidate/lib/validators";

    export default {
        name: "ChangeServer",
        components: { OttSelect, OttDialog, OttTextarea, OttButtonLoader },
        validations: {
            changeServerData: {
                mode: { required },
                server: { 
                    required: requiredIf(function () {
                       return this.serverMode !== 'restore'       
                    })
                },
                setSerever: { 
                    required: requiredIf(function () {
                       return this.serverMode !== 'restore' 
                    }) 
                },
                backup: { 
                    required: requiredIf(function () {
                       return this.serverMode === 'restore'  
                    }) 
                },
            },
        },
        props: {
            selectedItems: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            ...mapState({
                isOpenChangeServerModal: state => state.clientsModule.isOpenChangeServerModal,
                serverModeList: state => state.clientsModule.serverModeList,
                changeServerData: state => state.clientsModule.changeServerData,
                serverList: state => state.clientsModule.serverList,
                serverBackups: state => state.clientsModule.serverBackups,
                extendedType: state => state.clientsModule.extendedType,
                filterCount: state => state.clientsModule.filterModel.filterCount, 
                clientsFilter: state => state.clientsModule.filter,
                isLoading: state => state.appModule.isLoading
            }),
            btnText() {
                if (this.serverMode === 'restore') {
                    return 'Beckup'
                } else {
                    return 'Change Server'
                }
            },

            serverMode: {
                get() {
                    return this.changeServerData.mode
                },
                set(mode) {
                    this.setDataSecondLevels({ 
                        changeServerData: { mode } 
                    })
                }
            },

            server: {
                get() {
                    return this.changeServerData.server
                },

                set(server) {
                    this.setDataSecondLevels({ 
                        changeServerData: { server } 
                    })
                }
            },
            
            setSerever: {
                get() {
                    return this.changeServerData.setSerever
                },

                set(setSerever) {
                    this.setDataSecondLevels({ 
                        changeServerData: { setSerever } 
                    })
                }
            },
            
            backup: {
                get() {
                    return this.changeServerData.backup
                },

                set(backup) {
                    this.setDataSecondLevels({ 
                        changeServerData: { backup } 
                    })
                }
            },
        },
        methods: {
            ...mapActions({
                changeServer: 'clientsModule/changeServer',
                bekupServer: 'clientsModule/bekupServer'
            }),
            ...mapMutations({
                setData: 'clientsModule/setData',
                resetServerData: 'clientsModule/resetServerData',
                setDataSecondLevels: 'clientsModule/setDataSecondLevels'
            }),
            closeModal() {
                this.setData({ isOpenChangeServerModal: false })
                this.resetServerData()
            },
            async changeBtnFunc() {
                this.$v.changeServerData.$touch()
                const data = {};
                if (this.selectedItems.length) {
                    if (!this.extendedType) {
                        data.clients = [ ...this.selectedItems.map(item => item.id) ]
                    } else {
                        data.locations = [ ...this.selectedItems.map(item => item.id) ]
                    }
                }

                if (this.serverMode !== 'restore') {
                    data.mode = this.serverMode
                    data.hasServer = this.server
                    data.server = this.setSerever

                    if (this.serverMode === 2) {
                        if (this.filterCount.length) {
                            data.filter = { ...this.clientsFilter }
                        } else {
                            data.filter = {}
                        }
                    }
                }

                if (this.serverMode === 'restore') {
                    data.backup = this.backup
                }

                if (!this.$v.changeServerData.$error) {
                    if (this.serverMode !== 'restore') {
                        await this.changeServer(data).then(() => {
                            this.closeModal()
                        })
                    }

                    if (this.serverMode === 'restore') {
                        await this.bekupServer(data).then(() => {
                            this.closeModal()
                        })
                    }
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
.P-inputs {
    padding: 20px;
    /*min-height: 500px;*/
    &-block {
        /*width: 32%;*/
        margin-bottom: 20px;
    }
}

</style>
<template>
    <ott-dialog
        :is-open="clients.isOpenDeleteClientModal"
        :show-footer="false"
        :show-header="false"
        :width="400"
    >
        <template #modalBody>
            <div class="P-reject-modal">
                <div class="P-reject-modal__header G-align-center">
                    <span class="mdi mdi-alert-outline red--text"></span>
                    <h3 class="P-reject-modal__header-title">Delete client(s) ?</h3>
                </div>
                <div>
                    <p>You're about to Delete one or more clients!</p>
                    <p>If you're not sure, you can cancel this operation.</p>
                </div>
                <div class="P-reject-modal__footer G-justify-end">
                    <ott-button-loader
                        :is-loading="isLoading"
                        @onClick="deleteBtn"
                        button-text="Delete"
                        className="red-sm-btn"
                    />
                    <ott-button
                        :click="closeDeleteModal"
                        text="Cancel"
                        custom-class="transparent-sm-btn"
                    />
                </div>
            </div>
        </template>
    </ott-dialog>
</template>

<script>
    import {mapMutations, mapState} from "vuex";
    // components
    import OttDialog from "@/components/vuetifyComponents/OttDialog";
    import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
    import OttButton from "@/components/vuetifyComponents/OttButton";
    export default {
        name: "DeleteClient",
        components: {OttButton, OttButtonLoader, OttDialog},
        computed: {
            ...mapState({
                clients: state => state.clientsModule,
                isLoading: state => state.appModule.isLoading
            })
        },
        methods: {
            ...mapMutations({
                setIsOpenDeleteClientModal: 'clientsModule/setIsOpenDeleteClientModal'
            }),
            closeDeleteModal() {
                this.setIsOpenDeleteClientModal(false)
            },
            deleteBtn() {
                this.$emit('deleteClient')
            }
        }
    }
</script>

<style scoped lang="scss">
@import "src/assets/scss/deleteModal"
</style>
<template>
  <div class="P-users-list">
    <div class="G-page-component" :class="{ 'P-filter-open': isOpenFilter }">
      <div class="G-page-title-block G-flex G-justify-between G-align-center">
        <div class="G-component-title">
          <h3 class="secondary--text">Client</h3>
        </div>
        <ott-button
          v-if="permissionIs('createClient')"
          :click="openModal"
          class="primary-sm-btn"
          custom-class="approve d-flex"
          icon-before
          text="Add Client"
          icon-before-class="mdi mdi-plus d-inline"
        />
      </div>

      <div class="G-component-header page-background">
        <div class="G-table-actions G-flex G-align-center">
          <div class="action-cont-first G-flex G-align-center">
            <OttCheckbox
              :indeterminate="selectNotAll"
              :dataVal="selectAllList"
              tooltip="Select all visibles"
              @changeCheckbox="selectAllVisibles"
            />
            <div class="G-select-all">
              <OttCheckbox
                tooltip="Select all"
                v-model="isSelectedAll"
                @changeCheckbox="selectAll"
              />
            </div>  
            <div
              v-if="(selectedArrowList.length || selectedArrowListSub.length) && (permissionIs('updateClient') || permissionIs('deleteClient'))"
              class="approve-rej-icon-cont G-flex G-align-center"
            >
              <v-tooltip
                v-if="permissionIs('deleteClient')"
                top
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="P-settings" v-bind="attrs" v-on="on" @click="selectMultipleAction('delete')">
                    <span class="mdi mdi-delete-outline neutral--text P-icon" />
                  </div>
                </template>
                <span>Delete</span>
              </v-tooltip>
              <v-tooltip
                v-if="permissionIs('updateClientLocation')"
                top
                class="ott-tooltip"
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="P-settings" v-bind="attrs" v-on="on" @click="selectMultipleAction('blockLocations')">
                    <span class="mdi mdi-cancel neutral--text P-icon" />
                  </div>
                </template>
                <span>Block Locations</span>
              </v-tooltip>
              <v-tooltip
                v-if="permissionIs('updateClientLocation')"
                top
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="P-settings"
                    @click="selectMultipleAction('unblockLocations')"
                  >
                    <span
                      class="mdi mdi-check-circle-outline neutral--text P-icon"
                    ></span>
                  </div>
                </template>
                <span>Unblock Locations</span>
              </v-tooltip>
            </div>
            <div
              v-if="(selectedArrowList.length || selectedArrowListSub.length) && (permissionIs('updateClientLocation'))"
              class="ott-menu select-all-menu-cont P-settings"
            >
              <v-menu offset-y attach=".select-all-menu-cont">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="P-settings"
                    :ripple="false"
                  >
                    <span
                      class="P-icon mdi mdi-dots-vertical"
                      :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}"
                    ></span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(action, index) of multipleActions"  @click="selectMultipleAction(action.value)" :key="index" :ripple="false">
                    <div>
                      <v-list-item-title>{{ action.title }}</v-list-item-title>
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="G-excel-btn">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="P-settings" v-bind="attrs" v-on="on" @click="exportData">
                    <span class="mdi mdi-file-excel neutral--text P-icon" />
                  </div>
                </template>
                <span>Download Excel</span>
              </v-tooltip>
          </div>
          <div class="G-excel-btn">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div class="P-settings" v-bind="attrs" v-on="on" @click="openChangeServerModal">
                  <span class="mdi mdi-refresh-circle neutral--text P-icon" />
                </div>
              </template>
              <span>Change Server</span>
            </v-tooltip>
          </div>
          <OttHideShowColumnsOption
            :is-default="isDefault"
            :column-config="columnConfigData"
            @updateColumnsFromCheck="updateColumnsFromCheck"
            @resetDataSettingsTable="resetDataTableSettings"
            class="P-users-columns"
          />
          <div class="P-extended-table" v-if="false">
            <ott-switch label="Extended table" :val="extendedType" @emitFunction="updatedExtendedType" :value="extendedType === true" />
          </div>
        </div>
        <div>
          <div
            @click="toggleOpenFilter"
            :class="{ 'P-filter-open': isOpenFilter }"
            class="G-filter-btn"
          >
            <span class="mdi mdi-filter-variant"></span>
            <p>Filter</p>
            <div
              class="active-filter-count"
              v-if="filterModel.filterCount.length > 0"
            >
              <span>{{ filterModel.filterCount.length }}</span>
            </div>
            <span class="mdi mdi-menu-right"></span>
          </div>
        </div>
      </div>
      <div class="G-component-body page-background">
        <div class="G-sub-block-title">
          <h3 class="secondary--text">Client List</h3>
        </div>
        <OttUniversalTable
          v-if="!loading"
          :main-data="clients.dataTable"
          :column-config="columnConfigData"
          :selected-arrow-list="selectedArrowList"
          :data="clients.dataTable"
          :is-sub-table="false"
          :is-multi-select="true"
          :fixed-first-and-last-column="true"
          :column-with-tree-increment="tableColumnWidth"
          :is-total-sum="false"
          @saveColumnChangesFromDrag="updateColumnsFromDrag"
          @selectRow="selectRow"
          @sortData="sortColumnData"
          @updateColumnWith="updateColumn"
          @clickColumnData="openActivePackagesModal"
          @updateColumns="updateColumns"
        >
          <template v-if="permissionIs('updateClient') || permissionIs('deleteClient') || permissionIs('updateClientLocation')" slot-scope="props" slot="action">
            <v-menu left content-class="P-actions-list">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, i) in extendedType
                  ? actionsList(props.rowData.isBlockLocation, props.rowData.isPauseSubscriptions, props.rowData?.subscriptionState)
                  : actionsList(props.rowData.inBlock, props.rowData.inPause, props.rowData?.subscriptionState)
                  "
                  :key="i"
                  @click="selectAction($event, props, item.value)"
                >
                  <div>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </OttUniversalTable>
        <OttPaginationForTable
          v-if="clients.clientsResult && !loading"
          v-model="rowsPerPage"
          :data-count="clients.dataCount"
          :available-data-count="clients.dataTable.length"
          :current-page="clients.currentPage"
          :page-limit="clients.totalPages"
          @update="updateLimit"
          @updatePage="updateClientsTable"
        />
        <NoDataInTable v-else />
      </div>
    </div>
    <div
      class="G-filter-component page-background"
      :class="{ 'P-filter-open': isOpenFilter }"
    >
      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData @resetSelectedList="resetSelectedList" />
      </div>
      <div class="G-filter-footer">
        <ott-button
          :click="resetFilterData"
          class="primary-lg-btn"
          text="Reset Filters"
        >
        </ott-button>
      </div>
    </div>
    <PageLoader v-if="clients.isTableLoading || loading" />
    <ManageClientModal v-if="clients.modal.isOpen" @resetSelectedList="resetSelectedList" />
    <UsedDevicesModal v-if="usedDevicesClient.isOpenUsedDevicesModal" />      
    <ManagePackagesModal v-if="clients.isOpenManagePackagesModal"  />
    <CheckoutClientModal v-if="isOpenCheckoutModal" />
    <PackageListModal />
    <DeleteClient v-if="clients.isOpenDeleteClientModal" @deleteClient="rejectClient" />
    <WarningModal 
      v-if="clients.showWarningModal"
      :isModalOpen="clients.showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="closeWarningModal" 
      @btnFunction="warningModalBtnFunc"
    />
    <ActivePackagesModal v-if="clients.activePackagesModal" />
    <ChangeServer v-if="clients.isOpenChangeServerModal" :selectedItems="selectedArrowList" />
  </div>
</template>
<script>
//mixins
import ClientsMixin from "./ClientsMixin";
import OttUniversalTableForComponentsMixin from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
import FilterMixin from "@/mixins/FilterMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ColumnConfigMixin from "@/mixins/ColumnConfigMixin";
import CreditCardsMixin from "@/mixins/CreditCardsMixin";

//components
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttHideShowColumnsOption from "@/components/OttUniversalTable/OttHideShowColumnsOption";
import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
import PageLoader from "@/components/pageLoader/PageLoader";
import NoDataInTable from "@/components/NoDataInTable";
import FilterData from "./components/FilterData";
import DeleteClient from "./components/DeleteClient";

//modals
import ManageClientModal from "./modals/manageClient/ManageClient";
import UsedDevicesModal from "./modals/UsedDevicesModal";
import ManagePackagesModal from "./modals/ManagePackagesModal";
import PackageListModal from "./modals/PackagesListModal";
import CheckoutClientModal from "./modals/checkout/CheckoutClientModal";
import WarningModal from "@/components/WarningModal"
import ActivePackagesModal from "./modals/activePackages/ActivePackagesModal";
import OttRadio from "../../components/vuetifyComponents/OttRadio";
import OttSelect from "../../components/vuetifyComponents/OttSelect";
import ChangeServer from "./modals/ChangeServer";

export default {
  name: "Client",
  components: {
    ChangeServer,
    OttSelect,
    OttRadio,
    ActivePackagesModal,
    NoDataInTable,
    OttButton,
    OttCheckbox,
    OttSwitch,
    OttHideShowColumnsOption,
    OttUniversalTable,
    OttPaginationForTable,
    FilterData,
    PageLoader,
    DeleteClient,
    ManageClientModal,
    UsedDevicesModal,
    ManagePackagesModal,
    PackageListModal,
    CheckoutClientModal,
    WarningModal
  },
  mixins: [ ClientsMixin, OttUniversalTableForComponentsMixin, FilterMixin, DateFormatMixin, ColumnConfigMixin, CreditCardsMixin ],
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.P-users-columns {
  padding-left: 10px;
}

.P-filter-open {
  .mdi-menu-right {
    transform: rotate(180deg);
  }
}

.P-users-list {
  position: relative;
}

.approve-rej-icon-cont {
  font-size: 24px;

  .mdi {
    padding: 0 7px;
    display: inline-block;
  }

  .mdi-cancel,
  .mdi-check-circle-outline {
    border-radius: $border-radius;
  }
}

.approve-rej-icon-cont::v-deep {
  .v-tooltip__content {
    border-radius: 6px;
    padding: 7px 9px;
    font-size: $txt12;
  }
}

.select-all-menu-cont {
  .P-icon {
    font-size: 26px !important;
  }
  ::v-deep {
    .v-menu__content {
      max-width: 190px!important;
    }
  }
}

.select-all-menu-cont button .mdi-dots-vertical,
.settings-btn button .mdi-settings-outline {
  height: 38px;
  font-size: 24px;
  width: 38px;
  border-radius: 4px;
}

.theme--light {
  .active-filter-count {
    background-color: #e5f5f6 !important;
    color: $primary-color !important;
  }

  .action-cont-first-cont::v-deep {
    .v-icon {
      color: $neutral-color;
    }

    .mdi-minus-box {
      color: $primary-color !important;
    }
  }

  .select-all-menu-cont button[aria-expanded="true"] .mdi-dots-vertical,
  .settings-btn button[aria-expanded="true"] .mdi-settings-outline {
    background: $neutral-color15;
    color: $secondary-color !important;
  }

  .select-all-menu-cont button .mdi-dots-vertical:hover,
  .settings-btn button .mdi-settings-outline:hover {
    background: $neutral-color15;
    color: $secondary-color !important;
  }

  .icon-active {
    background: $neutral-color;
    color: $secondary-color !important;
    border-radius: $border-radius;
  }
}

.theme--dark {
  .action-cont-first-cont::v-deep {
    .v-icon {
      color: $neutral-colorDM !important;

      &.v-icon:after {
        opacity: 0;
      }
    }

    .mdi-minus-box {
      color: $primary-colorDM !important;
    }

    .primary--text {
      color: $primary-colorDM !important;
    }
  }

  .action-cont-first {
    border-right: 1.5px solid $borderDM;
  }
}

.G-table-actions {
  .v-menu__content {
    max-height: inherit!important;
  }
}

.v-menu__content.P-actions-list {
  max-height: inherit!important;
}

.G-excel-btn {
  border-left: unset;
  padding-left: unset;
}

.action-cont-first {
  display: flex;
  border-right: 1.5px solid $neutral-color;
  height: 36px;
  align-items: center;

  .v-input--selection-controls {
    margin-top: 23px;
  }
}

.P-icon {
  position: relative;
  z-index: 5;
  transition: $transition;
}

.P-providers-columns {
  .P-column-configs {
    max-width: 820px !important;
  }

  .P-column-configs ul li {
    width: 33.3%;
  }

  .v-menu__content {
    max-height: 520px !important;
  }
}

.P-extended-table {
  margin-left: 10px;
  margin-right: 18px;
  padding: 5px 20px;
  border-left: 1.5px solid $neutral-color;
}


.P-extended-table::v-deep {
  .v-input--selection-controls {
    margin: 0;
  }
}

::v-deep {
  .P-client-type {
    .v-input--selection-controls {
      margin: 0;
      padding: 0;
    }
    .Ott-select-cont {
      width: 200px;
    }
  }
  .v-text-field__details {
    display: none;
  }
  .v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0;
  }
}
.P-radio-box {
  margin-right: 15px;
}

.P-manage-packages {
  padding-left: 28px;
  &__btn {
    margin-right: 22px;
  }
}

  ::v-deep {
    .v-input__slot {
      margin-bottom: 0;
    }
    
    .G-paused-badge-time {
      width: max-content;
      padding: 0 10px;
    }
  }
</style>

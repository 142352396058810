
<template>
  <ott-dialog
    v-if="usedDevicesClient.isOpenUsedDevicesModal"
    @outside="closeUsedDevicesModal"
    @resetDataModal="closeUsedDevicesModal"
    modal-title="Manage Used Devices"
    :is-open="usedDevicesClient.isOpenUsedDevicesModal"
    :show-footer="false"
    :width="1120"
  >
    <template #modalBody>
      <div
        class="
          P-manage-packages-modal-body
          d-flex
          flex-column
          justify-space-between
        "
      >
        <div class="P-manage-packages-modal-body__content">
          <UsedDevicesClients v-if="locationList.length" ref="usedDevices" />

          <p v-else class="text-center mb-0 mt-3">
            There are no created locations
          </p>
        </div>

      </div>
    </template>
  </ott-dialog>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import UsedDevicesClients from "./manageClient/usedDevices/UsedDevices";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "UsedDevicesModal",
  components: {
    OttDialog,
    UsedDevicesClients,
    OttButton,
    OttButtonLoader,
  },
  computed: {
    ...mapState({
      clients: (state) => state.clientsModule,
      locationList: (state) => state.locationClient.locationList,
      usedDevicesClient: (state) => state.usedDevicesClient,
      usedDevices: (state) => state.usedDevicesClient.usedDevices,
    }),

    isAvailableDevices() {
      let availableDevices = false;
      this.usedDevices.length
        ? (availableDevices = true)
        : (availableDevices = false);

      return availableDevices;
    },
  },

  destroyed() {
    this.resetDataModal();
  },

  methods: {
    ...mapMutations({
      setData: "usedDevicesClient/setData",
      closeModal: "clientsModule/closeManagePackagesModal",
      setClientId: "clientsModule/setClientId",
      locationReset: "locationClient/resetAllData",
      closeUsedDevices: "usedDevicesClient/closeUsedDevicesModal",
    }),

    closeUsedDevicesModal() {
      this.closeUsedDevices();
    },

    resetDataModal() {
      this.closeModal();
      this.setClientId(null);
      this.locationReset();
    },

    async save() {
      await this.saveUsedDevices();

      this.setData({
        selectedDeviceIndex: null,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";
.P-manage-packages-modal-body {
  min-height: 660px;
  &__content {
    padding: 15px 24px 24px;
  }
  &__bottom-block {
    border: 1px solid $neutral-color;
    padding: 24px;
    .P-cancel-btn {
      margin-right: 24px;
    }
  }
}
</style>
<template>
  <div class="P-manage-packages">
    <ott-dialog
      :width="1120"
      :is-open="clients.isOpenManagePackagesModal"
      :show-footer="false"
      @outside="resetDataModal"
      @resetDataModal="resetDataModal"
      modal-title="Manage Packages"
    >
      <template #modalBody>
        <div class="P-manage-packages-modal-body d-flex flex-column justify-space-between">
          <div class="P-manage-packages-modal-body__content">
            <template v-if="locationList.length">
              <PackagesClients ref="packagesClients" />
            </template>

            <p v-else class="text-center mb-0 mt-3">
              There are no created locations
            </p>
          </div>

          <div class="P-manage-packages-modal-body__bottom-block G-justify-end">
            <ott-button
              :click="resetDataModal"
              custom-class="neutral-md-btn me-4"
              text="Cancel"
            />
            <ott-button-loader
              v-if="showPackagesPayBtn && locationList.length"
              :disabled="!allowCheckout"
              :button-text="packagesPayBtnLabel"
              :is-loading="isLoading"
              @onClick="saveOrCheckout"
              className="primary-sm-btn P-login-btn"
            />
          </div>
        </div>
      </template>
    </ott-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import PackagesClients from "./manageClient/packages/Packages";

import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "ManagePackagesModal",
  components: {
    OttDialog,
    PackagesClients,
    OttButton,
    OttButtonLoader,
  },
  computed: {
    ...mapState({
      clients: (state) => state.clientsModule,
      locationList: (state) => state.locationClient.locationList,
      showPackagesPayBtn: (state) => state.packagesClients.showPayBtn,
      packagesPayBtnLabel: (state) => state.packagesClients.packagesPayBtnLabel,
      allowCheckout: (state) => state.packagesClients.allowCheckout,
      dataForPackages: (state) => state.packagesClients.dataForPackages,
      isLoading: (state) => state.appModule.isLoading
    }),
  },

  async destroyed() {
    this.closeModal();
    await this.getClientList()
  },

  methods: {
    ...mapActions({
      setCheckoutInvoice: 'packagesClients/setCheckoutInvoice',
      getClientList: 'clientsModule/getClientList',
    }),

    ...mapMutations({
      closeModal: 'clientsModule/closeManagePackagesModal',
      setClientId: 'clientsModule/setClientId',
      locationReset: 'locationClient/resetAllData',
      openCheckoutModal: 'checkoutClients/openModal',
      setDataClientEquipments: 'equipments/setData'
    }),

    resetDataModal() {
      this.closeModal();
      this.setClientId(null);
      this.locationReset();
      this.setDataClientEquipments({ clientEquipments: [] })
    },

    async saveOrCheckout() {
      await this.setCheckoutInvoice({
        ...this.dataForPackages,
      }).then(r => r ? this.openCheckoutModal() : '');
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";
::v-deep{
  .P-table-body, .P-table-header {
    min-width: unset;
  }

  .P-table-body {
    max-width: 1072px;
  }

  .P-table-header {
    max-width: 1070px;
  }
}
.P-manage-packages-modal-body {  
  min-height: 660px;
  &__content {
    padding: 15px 24px 24px;   
  }
  &__bottom-block {
    border: 1px solid $neutral-color;
    padding: 24px;
    .P-cancel-btn {
      margin-right: 24px;
    }
  }
}
</style>